.itemContainer {
  height: 40px;
  background-color: var(--color-white);
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--color-quick-silver);
  cursor: pointer;
}

.item {
  display: flex;
  flex-direction: row;
  margin-left: 25.41px;
  cursor: pointer;
}

.icon {
  width: 35px;
  display: flex;
  justify-content: center;
  margin-right: 20px;
}

.selected {
  color: var(--color-light-salmon) !important;
}

.disabled {
  pointer-events: none;
}
