.container {
  font-family: var(--font-family-title);
}

.header {
  margin-bottom: 10px;
}

.tab + .tab {
  margin-left: 10px;
}

.block > div {
  padding: 15px 20px;
  margin-top: 2px;
}

.general > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infos {
  display: flex;
  flex-direction: row;
}

.infos p {
  font-size: 14px;
  margin: 6px 0;
}

.infos p + p {
  margin-left: 5px;
}

.favorite {
  margin-left: auto;
  cursor: pointer;
}

.effectsIcons,
.ingredientIcons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.effect {
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  align-items: center;
  max-width: 100px;
}

.effectIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  width: 64px;
}

.ingredientIcon {
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.iconSubtitle {
  font-weight: 500;
  text-align: center;
}

.icon {
  height: 100px;
  width: 100px;
}

.questions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 18px;
}

.questions:first-of-type {
  margin: 0;
}

.answers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.answer + .answer {
  margin-top: 8px;
}

.button {
  width: 100%;
}

.loading {
  margin-top: 100px;
}

.lastContent {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .header {
    margin: 20px 0 40px;
  }

  .button {
    width: 45%;
  }

  .block.button > div {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}
