.container {
  display: flex;
  flex-flow: column;
}

.container,
.blocks,
.block {
  height: 100%;
}

.header {
  margin-bottom: 20px;
}

.block > div {
  height: 100%;
  padding: 20px 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-family: var(--font-family-title);
}

.inputs {
  width: 100%;
  flex: 1 1 auto;
}

.input {
  margin-top: 15px;
}

.button {
  width: 100%;
  margin: 20px 0 40px;
}

.modalHeader {
  display: flex;
  align-items: center;
  width: 100%;
}

.modalHeader p {
  margin: 0;
}

.modalHeaderIcon {
  width: 50px;
  height: 40px;
  margin-right: 10px;
}

.modalInput {
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .header {
    margin: 20px 0 40px;
  }

  .block {
    display: flex;
    justify-content: center;
  }

  .block > div {
    max-width: 700px;
    padding: 40px 80px 30px;
    min-height: 435px;
    max-height: 510px;
  }

  .shortInputs {
    display: flex;
    justify-content: space-between;
  }

  .shortInputs .input {
    width: 49%;
  }

  .button {
    width: 289px;
    margin: 20px 0;
  }
}
