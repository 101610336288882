:root {
  --color-white: #fff;
  --color-black: #000;
  --color-international-orange-aerospace: #ff4e00;
  --color-flame: #dd5316;
  --color-copper-red: #d56e42;
  --color-copper-crayola: #e48257;
  --color-rajah: #ffa551;
  --color-coral: #ff8c5b;
  --color-dark-salmon: #eb936d;
  --color-light-salmon: #f4a27e;
  --color-light-salmon-lighter: #ffb899;
  --color-deep-champagne: #fcd4a4;
  --color-banana-mania: #ffe6ab;
  --color-linen: #f9eae4;
  --color-isabelline: #f8f3ef;
  --color-davys-grey: #58595a;
  --color-dim-gray: #616161;
  --color-sonic-silver: #75797c;
  --color-quick-silver: #9ca1a5;
  --color-silver: #c0c0c0;
  --color-light-gray: #cfcfcf;
  --color-platinum: #e1e1e1;
  --color-cultured-darker: #eee;
  --color-cultured: #f4f4f4;
  --color-ocean-green: #3bbd86;
  --color-cadet-blue: #74a8a5;
  --color-cambridge-blue: #8eb3a3;
  --color-aero-blue-darker: #bbe2d1;
  --color-aero-blue: #bfead7;
  --color-maximum-red: #d91717;
  --color-french-raspberry: #ce354b;
  --color-shimmering-blush: #e18890;
  --color-new-york-pink: #ce8181;
  --color-burnt-sienna: #f3795b;
  --color-new-york-pink-rgb: 206, 129, 129;
  --color-champagne-pink: #f1dad0;
  --color-lavender-blush: #ffee;
  --color-rich-black: #003f40;
  --color-gray-web: #7f8386;

  --font-family-title: 'Montserrat', sans-serif;
  --font-family-body: 'Roboto', sans-serif;

  --font-size-h1: 16px;
  --font-size-h2: 14px;
  --font-size-h3: 12px;
  --font-size-body: 12px;
  --font-size-input: 12px;
  --font-size-checkbox: 10px;
  --text-size-help-popup: 9px;
  --font-size-button-primary: 16px;
  --font-size-button: 12px;
}

/* stylelint-disable */

html,
body,
#root {
  height: 100%;
  margin: 0;
}

/* prettier-ignore */
html, body, div, article, aside, section, main, nav, footer, header, form, fieldset, legend, pre, code, a, h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd, blockquote, figcaption, figure, textarea, table, td, th, tr, input {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

body {
  font-family: var(--font-family-body);
  font-size: var(--font-size-body);
  background-color: var(--color-cultured-darker);
}

/* prettier-ignore */
h1, h2, h3,
.h1, .h2, .h3 {
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: bold;
}

/* prettier-ignore */
h1, .f1 {
  font-size: var(--font-size-h1);
}

/* prettier-ignore */
h2, .f2 {
  font-size: var(--font-size-h2);
}

/* prettier-ignore */
h3, .f3 {
  font-size: var(--font-size-h3);
}

/* by default, all placeholders in Firefox have an opacity value applied to them
   so in order to fix this we need to reset that value */
::-moz-placeholder {
  opacity: 1;
}

/* prettier-ignore */
input, input::-webkit-input-placeholder {
  font-size: var(--font-size-input);
}

button,
a {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Zendesk widget */
div[role="presentation"] {
  display: none;
}

div[role="presentation"] div {
  bottom: 8px !important;
  z-index: 1001 !important;
}

div[role="presentation"] > iframe {
  display: none !important;
}
