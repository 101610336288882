.container {
  height: 100%;
  flex-flow: column;
  padding-bottom: 20px;
  min-height: 460px;
  min-width: 265px;
}

.header {
  margin-bottom: 20px;
}

.block,
.block > div {
  height: 100%;
  font-family: var(--font-family-title);
}

.block > div {
  padding: 28px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  margin: 0;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 13px;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  margin-top: 13px;
}

.input {
  margin-top: 7px;
}

.inputLabel {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.inputHint {
  margin: 7px 0 0;
  font-size: 10px;
  line-height: 12px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons > button {
  width: 258px;
  font-size: var(--font-size-button-primary);
}

@media (min-width: 768px) {
  .container .header {
    margin: 20px 0 60px;
  }

  .container > .header > svg {
    width: 96px;
  }

  .block > div {
    max-width: 802px;
    padding: 40px 80px 30px;
    max-height: 434px;
  }
}
