.cardChoiceContainer {
  display: flex;
  flex-direction: column;
  padding: 16px 40px;
}

.choiceRootContainer {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.cardGeneralInfos {
  font-weight: 500;
  font-size: 12px;
}

.cardExpireInfos {
  font-weight: 500;
  color: var(--color-sonic-silver);
}

.transparentBlock {
  width: 100%;
  overflow: auto;
}
.noContent {
  min-height: 10vh;
  width: 100%;
  display: flex;
  justify-content: center;

  align-items: center;
  overflow: auto;
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;

  padding: 50px;
  text-align: center;
  margin-top: 0;
}
.loadingContainer {
  min-height: 20vh;
  width: 100%;
}
.ContentModal {
  font-weight: 400;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}
.loadingContainer {
  min-height: 20vh;
  width: 100%;
}
.ContentModal {
  font-weight: 400;
  font-size: 1rem;
  padding-bottom: 0.5rem;
}

.pageRootContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.buttonContainer {
  width: 100%;
}

.header {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .header {
    margin: 20px 0 40px;
  }
}
