.transparentBlock {
  width: 100%;
  overflow: auto;
}

.cardGeneralInfos {
  font-weight: 500;
  font-size: 12px;
}

.cardExpireInfos {
  font-weight: 500;
  color: var(--color-sonic-silver);
}

.cardChoiceContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
}

.choiceRootContainer {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid silver;
}
