.icons {
  display: flex;
  width: 100%;
  align-items: center;
}

.icon {
  flex-wrap: wrap;
}

.icon,
.divider {
  flex-grow: 1;
  width: 100%;
}

.divider {
  border-bottom: 1px solid #000;
}

.divider:first-child,
.divider:last-child {
  border: unset;
  width: 50%;
}

.titles {
  display: flex;
  margin: 8px 0;
  width: 100%;
}
@media (max-width: 767px) {
  .titles {
    display: flex;
    margin: 8px 0;
    width: 100%;
    font-size: 0.6rem;
  }
}

.titles h5 {
  margin: 0;
  flex-grow: 1;
  width: 100%;
  text-align: center;
  font-family: var(--font-family-title);
  font-weight: 500;
}

.active {
  color: #ff8717;
}
