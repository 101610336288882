.container {
  font-family: var(--font-family-title);
}

.header {
  margin-bottom: 20px;
}

.block {
  margin-bottom: 10px;
}

.block > div {
  font-weight: 500;
  padding: 18px 20px;
}

.contactBy {
  margin-top: 2px;
  margin-bottom: 10px;
}

.contactOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact {
  margin-bottom: 15px;
}

.contact a,
.contact div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.contact p {
  color: var(--color-black);
  text-align: center;
}

.contact p span {
  color: var(--color-rajah);
}

.icon {
  margin-top: 30px;
}

@media (min-width: 768px) {
  .header {
    margin: 20px 0 40px;
  }

  .block {
    margin-bottom: 20px;
  }

  .contactOptions {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
