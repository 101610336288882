.header {
  margin-bottom: 30px;
}

.block > div {
  background: none !important;
}

.emptyListText {
  width: 100%;
  margin: 20px 0;
}

.choiceGroup {
  background-color: var(--color-cultured-darker);
}

.choiceGroup > .card {
  height: 130px;
  margin-bottom: 16px;
  border: none;
}

.card p {
  margin: 0;
  font-weight: 400;
  font-family: var(--font-family-title);
}

.address {
  padding: 10px 20px 15px;
}

.card button {
  margin-left: auto;
  z-index: 10;
}

.buttons,
.buttons button {
  width: 100%;
}

.buttons button {
  margin-top: 10px;
  font-size: var(--font-size-button-primary);
}

.modalContent {
  font-size: var(--font-size-h2);
  margin: 15px 0 25px;
  text-align: center;
}

.emptyListText {
  padding: 20px;
  font-size: var(--font-size-h2);
  text-align: center;
  margin-top: 0;
}

@media (min-width: 768px) {
  .header {
    margin: 20px 0 40px;
  }

  .buttons {
    width: 45%;
  }

  .modalContent {
    margin: 10px 0 35px;
  }
}
