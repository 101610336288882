.blocks {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timeline {
  padding: 5px 5px;
}

.timelineIcons {
  height: 50px;
}

.welcomeContainer {
  color: var(--color-light-salmon);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.subtitleContainer {
  font-family: var(--font-family-title);
  margin-bottom: 23px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.homePageRoot {
  display: flex;
  flex-direction: column;
}

.lastOrderContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}

.attributeContainer {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  font-family: var(--font-family-title);
}

.attributeName {
  font-weight: 700;
}

.attributeValue {
  margin-left: 6px;
  font-weight: 500;
  font-size: 12px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 8px;
}

.buttonStyle {
  width: 90%;
  font-size: var(--font-size-button-primary);
}

.lastButton {
  margin-bottom: 24px;
}

.newFormulaContainer {
  display: flex;
  flex-direction: column;
}

.dropperContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 40px 0;
}

.newFormulaRootContainer {
  margin: 30px 0 20px;
  display: flex;
  flex-direction: column;
}

.newFormulaHelperText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 12px 15px 0;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--font-family-title);
}

.createFormulaButton {
  margin-bottom: 24px;
}

.titleContainer {
  font-size: 16px;
  text-align: center;
  font-weight: normal;
}

@media (min-width: 768px) {
  .lastOrderContainer {
    padding: 10px 40px;
  }

  .timeline {
    padding: 20px 20%;
  }

  .buttonStyle {
    width: 40%;
  }

  .newFormulaRootContainer {
    margin-bottom: 40px;
  }

  .newFormulaHelperText {
    margin-left: 40px;
  }
}
