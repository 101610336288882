.logoContainer {
    background-color: var(--color-light-salmon);
    height: 195px;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .jfyLogo {
    width: 143px;
    height: 57px;
  }