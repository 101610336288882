.choice {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  background-color: var(--color-white);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cardSeparator {
  border-bottom: 1px solid black;
}

.wrap {
  padding: 4px 11px;
  margin: 8px 0;
  display: flex;
}

.deleteIconContainer {
  height: 26px;
  width: 23px;
  border: 1px solid black;
}

.iconStyle {
  display: flex;
  flex-direction: column;
  height: 18px;
  width: 19px;
  margin-top: 3px;
  margin-left: 1px;
  z-index: 10px;
}

.radio {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.title {
  padding-left: 0.7rem;
}
.choice .title {
  margin: 0;
  font-family: var(--font-family-title);
  font-size: var(--font-size-h2);
}

.choice .description {
  margin: 4px 0 0;
  color: var(--color-quick-silver);
}

.choice .infoWrap {
  display: flex;
  width: 100%;
  padding: 7px;
  font-family: var(--font-family-title);
  align-items: center;
  justify-content: flex-end;
}

.choice .info {
  margin: 0;
  font-size: var(--font-size-h2);
}

/* Disabled */
.choice.disabled {
  cursor: default;
  border: 1px solid var(--color-sonic-silver);
}

.choice.disabled .input {
  cursor: default;
}

.choice.disabled .title,
.choice.disabled .description {
  color: var(--color-silver);
}

.choice.disabled .info {
  color: var(--color-platinum);
}

/* Disabled Selected */
.choice.disabled.selected .info {
  opacity: 0.7;
}

.choice.disabled.selected::after {
  background-color: var(--color-champagne-pink);
}

.choice.disabled.selected {
  border: 1px solid var(--color-champagne-pink);
}

/* Variants */

/* Normal */
.choice.normal:not(.disabled):hover,
.choice.normal:not(.disabled).hover {
  border: 1px solid var(--color-dark-salmon);
}

.choice.normal.selected {
  border: 1px solid var(--color-light-salmon);
}

/* Collapsible */
.choice.collapsible {
  border: 1px solid var(--color-silver);
}

.choice.collapsible:hover,
.choice.collapsible.hover {
  border: 1px solid var(--color-quick-silver);
}

.choice.collapsible .input {
  margin-right: 11px;
}

.choice.collapsible .content {
  max-height: 0;
  overflow: hidden;
}

.choice.collapsible.selected {
  cursor: default;
  border: 1px solid var(--color-light-salmon);
}

.choice.collapsible.selected .content {
  max-height: 100%;
  padding: 0 10px;
}
