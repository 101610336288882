.header {
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header .title {
  width: 80%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
}

@media (min-width: 320px) and (max-width: 1023px) {
  .header .title h1 {
    text-align: center;
  }
}

.header .title h1 {
  font-weight: normal;
  margin: 0;
  word-break: break-word;
  font-weight: 500;
}

.header .iconGoBack {
  position: absolute;
  width: 12px;
  height: auto;
  cursor: pointer;
  left: 5.56%;
}

.header .iconGoBack svg {
  align-self: center;
}

.header .logo {
  width: 65px;
  height: auto;
  align-self: center;
  margin: 0 auto;
}
