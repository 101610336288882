/* Start of styles shared by both cards */
.card .title {
  width: 100%;
  padding: 14px 15px 11px;
  background: var(--color-white);
  border-bottom: 1.5px solid var(--color-black);
}

.card .title div {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card .title div h2 + h2 {
  margin-left: auto;
  font-weight: normal;
}

.card .title h2,
.card .title p,
.card .content p {
  margin: 0;
}

.card .content {
  width: 100%;
  background: var(--color-white);
  padding: 15px;
  font-family: var(--font-family-title);
}

/* End of styles shared by both cards */

.card .formula {
  display: flex;
  margin-bottom: 10px;
}

.formula p {
  padding-right: 15px;
}

.formula .favorite {
  margin: 0 5px 0 auto;
  cursor: pointer;
}

.formulaButton {
  margin-left: auto;
}

.order .info {
  display: flex;
}

.order .info > p {
  margin-left: auto;
}

.order .info p {
  margin-bottom: 5px;
}
