.content {
  display: flex;
  font-family: var(--font-family-title);
}

.icon {
  padding: 0 12px 0 0;
}

.content p {
  margin: 0;
  margin-left: 5px;
  color: var(--color-black);
}

.deliveryBackground {
  background: #f4a27e !important;
}

.deliveryBackground p {
  color: #fff !important;
}

.deliveryBackground p u {
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .deliveryBackground {
    width: 650px;
    color: #fff !important;
  }
}

.deliveryFont {
  color: #fff !important;
  font-size: 10pt;
  font-family: 'Arial', sans-serif;
}
