.cardRootContainer {
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
}

.creditCardPreviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardPreviewStyle {
  display: flex;
  flex-direction: column;
}

.label {
  padding: 17px 0 7px;
  margin: 0;
  font-weight: 600;
}

.shortInputs {
  display: flex;
  width: 100%;
}

.shortInputs > div {
  width: 50%;
}

.expiration,
.document {
  margin-right: 10px;
}

.select {
  width: 100%;
  height: 30px;
  font-family: var(--font-family-title);
  font-size: var(--font-size-input);
  font-weight: 500;
  background-color: #fff;
  border: 1px solid var(--color-gray-web);
  outline: none;
}

/* Selector Hack for Safari only */
_::-webkit-full-page-media,
_:future,
:root .select {
  appearance: none;
  padding: 7px 12px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.795 3.377a.232.232 0 0 1 .328.005l3.338 3.427c.09.092.236.094.328.005l3.428-3.338a.232.232 0 0 1 .328.005l.647.664a.232.232 0 0 1-.004.328L5.763 8.782a.232.232 0 0 1-.328-.005L1.126 4.352a.232.232 0 0 1 .004-.328l.665-.647Z' fill='%237F8386'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center, right bottom, right bottom,
    right bottom;
  background-size: 18px;
  border-radius: 0;
}

.select:hover {
  opacity: 0.93;
}

.button {
  width: 100%;
  margin: 20px 0 15px;
}

.leftBlock {
  width: 100%;
  margin-top: 16px;
}

.rightBlock {
  width: 100%;
}

.onlyDesktop {
  display: none;
}

.discount {
  color: var(--color-cadet-blue);
}

.checkbox {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

@media (max-width: 350px) {
  .product {
    padding: 0 10px 20px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .onlyDesktop {
    display: inline;
  }

  .blocks {
    flex-direction: row-reverse;
  }

  .leftBlock {
    width: 69%;
    margin-top: 0;
  }

  .rightBlock {
    width: 29%;
    margin-left: 2%;
  }

  .kit .price,
  .subtotal .price {
    font-weight: 600;
  }

  .subtotal {
    padding: 13px 18px;
    margin: 0;
  }

  .prices {
    padding: 13px 18px;
    margin: 0;
  }

  .paymentMethods {
    padding: 20px 30px 10px;
  }

  .numberValidAndCvv {
    display: flex;
  }

  .numberValidAndCvv > div {
    width: 50%;
  }

  .numberValidAndCvv > div:first-child {
    padding-right: 5px;
  }

  .numberValidAndCvv > div:last-child {
    padding-left: 5px;
  }

  .button {
    width: 100%;
    max-width: 280px;
    margin: 20px auto 15px;
  }
}

.header {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .header {
    margin: 20px 0 40px;
  }
}
