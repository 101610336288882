.container {
    padding-bottom: 30px;
}

.header {
    padding: 10px 0px 10px;
}

.block {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2px;
}

.selectContainer {
    position: relative;
    display: inline-flex;
    width: 100%;
    border: 1px solid var(--color-gray-web);
    padding: 7px 12px;
    font-family: var(--font-family-title);
    font-size: var(--font-size-h3);
    font-weight: 500;
    color: var(--color-black);
}

.emailContainer {
    display: flex;
    flex-direction: column;
    padding: 7px 12px;
}

.labels {
    font-size: var(--font-size-h3);
    font-weight: 500;
    padding: 10px 0px;
}

.textarea {
    font-family: var(--font-family-title);
    font-size: var(--font-size-h3);
    font-weight: 500;
    color: var(--color-black);
    border: 1px solid var(--color-gray-web);
    padding: 7px 12px;
    margin-bottom: 10px;
}

.button {
    width: 50%;
}

.buttonContainer {
    display:flex;
    flex-direction: column;
    align-items: center;
}
