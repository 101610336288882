.displayRootContainer {
  display: flex;
  flex-direction: row;
  height: 141px;
  width: 283px;
  background-color: white;
  font-family: var(--font-family-title);
}

.outlined {
  border: 1px solid black;
}

.productImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 64px;
  height: 100%;
  padding: 19px 10px;
}

.benefitImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 192px;
  height: 100%;
  padding: 19px 10px;
}

.productInfosContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 19px 1px;
  justify-content: center;
}

.titleContainer {
  display: flex;
  font-weight: 700;
  font-size: 12px;
  height: 21px;
}

.sizeContainer {
  display: flex;
  font-weight: 500;
  font-size: 12px;
  height: 21px;
}

.priceContainer {
  display: flex;
  font-weight: 700;
  font-size: 12px;
  height: 21px;
}

.seeDescriptionContainer {
  display: flex;
  margin-top: 10px;
  height: 17px;
  width: 157px;
  border-top: 1px solid black;
  border-right: 1px solid black;
}

.seeDescriptionText {
  color: var(--color-international-orange-aerospace);
  font-weight: 700;
  font-size: 12px;
  font-family: var(--font-family-title);
  margin-top: 5px;
  margin-left: 50px;
}

.imageContent {
  width: 30px;
  height: 98px;
}
