.container {
  padding-bottom: 30px;
}

.header {
  margin: 14px 0 25px;
}

.block {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2px;
}

.block > div {
  padding: 10px 10px 20px;
}

.timelineIcons {
  height: 50px;
}

.attribute {
  display: flex;
  margin-top: 8px;
  font-family: var(--font-family-title);
  font-size: var(--font-size-h2);
}

.attribute .name {
  font-weight: bold;
}

.attribute .value {
  margin-left: 6px;
  font-weight: 500;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons button {
  width: 100%;
  margin-top: 10px;
  font-size: var(--font-size-button-primary);
}

.button {
  width: 100%;
  margin-top: 10px;
  font-size: var(--font-size-button-primary);
}

.items {
  width: 100%;
}

.formulaName {
  font-weight: 500;
}

.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.products .product {
  margin: 4px;
}

.formulas {
  width: 100%;
}

.formula {
  margin: 30px 0;
}

.formula button {
  margin: 5px 0 0 auto;
}

.desktop {
  display: none;
}

@media (min-width: 660px) {
  .products {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .header {
    margin: 20px 0 40px;
  }

  .block {
    margin-bottom: 15px;
  }

  .timeline {
    padding: 20px 20%;
  }

  .formula {
    margin: 0;
  }

  .block.buttons > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
  }

  .buttons button {
    width: 45%;
  }
}
