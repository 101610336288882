.header {
  margin-bottom: 20px;
}

.blocks {
  margin-bottom: 20px;
}

.blocks > div {
  padding: 18px 20px;
  display: flex;
  align-items: center;
}

.blocks svg {
  width: 80px;
  height: auto;
  margin-right: 18px;
}

.blocks p {
  font-family: var(--font-family-title);
  margin: 0;
}

.blocks p span {
  font-weight: 600;
}

.blocks button {
  margin: 15px 0 0 auto;
}

.button {
  margin-bottom: 30px;
  width: 100%;
}

@media (min-width: 768px) {
  .button {
    width: 40%;
  }
}
