.header {
  margin-bottom: 10px;
}

.card {
  margin-top: 15px;
}

.loading,
.message {
  margin: 70px 0 50px;
}

.message {
  text-align: center;
}

.button {
  width: 100%;
  margin: 20px 0 40px;
}

.containerTabList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

@media (min-width: 768px) {
  .header {
    margin: 20px 0 40px;
  }

  .button {
    width: 45%;
  }
}
