.tabs {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.list {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tab {
  list-style: none;
  cursor: pointer;
  padding: 12px 25px;
  margin: 0 10px;
  background-color: var(--color-white);
  font-family: var(--font-family-title);
  font-weight: bold;
  white-space: nowrap;
}

.tab:first-of-type,
.tab:last-of-type {
  margin: 0;
}

.tab.active {
  background: var(--color-light-salmon);
  color: var(--color-white);
}

.list::-webkit-scrollbar,
.list::-webkit-scrollbar-button,
.list::-webkit-scrollbar-thumb,
.list::-webkit-scrollbar-track,
.list::-webkit-scrollbar-track-piece,
.list::-webkit-scrollbar-corner,
.list::-webkit-resizer {
  display: none;
}
