.rootStyle {
  display: flex;
  flex-direction: column;
}

.blockStyle {
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 30px;
}

.info {
  margin: 0px 0px 0px 5px;
  padding: 0.5px;
  color: var(--color-light-salmon);
  cursor: pointer;
  border: 2px solid var(--color-light-salmon);
}

.inputLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}

.defaultFormMargin {
  margin-bottom: 10px;
}

.formRootStyle {
  padding: 12px 19px;
}

.zipStateRow {
  display: flex;
  flex-direction: row;
}

.zipBlock {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-right: 9px;
}

.stateBlock {
  display: flex;
  flex-direction: column;
}

.numberNeighborRow {
  display: flex;
  flex-direction: row;
}

.numberBlock {
  display: flex;
  flex-direction: column;
  width: 39%;
  margin-right: 9px;
}

.neighborBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20px;
  justify-content: space-around;
}

.buttonStyle {
  width: 100%;
}
