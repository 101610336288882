.container {
  height: 100%;
  flex-flow: column;
  padding-bottom: 20px;
  min-height: 460px;
  min-width: 265px;
}

.header {
  margin: 0 0 20px;
}

.block,
.block > div {
  height: 100%;
}

.block > div {
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
  text-align: center;
}

.input {
  margin-top: 26px;
  flex: 1 1 auto;
}

.input h1 {
  margin: 0 0 7px;
  font-size: var(--font-size-h2);
}

.feedback {
  flex: 2 1 auto;
  font-family: var(--font-family-title);
}

.buttons {
  width: 100%;
}

.buttons button {
  width: 100%;
  font-size: var(--font-size-button-primary);
  margin-top: 13px;
}

@media (min-width: 768px) {
  .container

  .header {
    margin: 20px 0 60px;
  }

  .container > .header > svg {
    width: 96px;
  }

  .block {
    display: flex;
    justify-content: center;
  }

  .block > div {
    max-width: 700px;
    padding: 40px 80px 30px;
    height: 75%;
    min-height: 365px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buttons > button {
    max-width: 300px;
  }
}
