.block {
  display: flex;
  justify-content: center;
}

.block > div {
  padding: 20px 30px;
  max-width: 700px;
}

.header {
  margin: 0 0 20px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.userInfosContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
}

.inputLabel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 700;
  font-family: var(--font-family-title);
  font-size: var(--font-size-h2);
}

.inputStyle {
  margin-top: 7px;
}

.button {
  margin-top: 30px;
  width: 100%;
}

.formInfoRootContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.shortInputsRow {
  width: 100%;
}

@media (min-width: 768px) {
  .header {
    margin: 20px 0 50px;
  }

  .container .header > svg {
    width: 96px;
  }

  .block > div {
    max-width: 700px;
    padding: 20px 80px 40px;
    margin-bottom: 55px;
    min-height: 365px;
  }

  .userInfosContainer,
  .shortInputsRow {
    display: flex;
    width: 100%;
  }

  .shortInputStyle {
    width: 95% !important;
  }

  .button {
    max-width: 300px;
    margin: 40px auto 0;
  }
}
