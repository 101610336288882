.header {
  margin-bottom: 10px;
}

.orders {
  margin-bottom: 40px;
}

.card {
  width: 100%;
  margin-top: 15px;
}

.loading {
  margin: 50px 0;
}

.pages {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.emptyListText {
  margin: 50px 0;
  width: 100%;
  text-align: center;
}

.containerTabList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

@media (min-width: 768px) {
  .header {
    margin: 20px 0 40px;
  }

  .pages {
    margin-bottom: 40px;
  }
}
