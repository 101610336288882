.clientAreaContainer {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px 20px;
}

.blocksWrap {
  flex: 1 1 auto;
  justify-content: center;
  display: flex;
  width: 100%;
}

.blocks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: space-around;
}

.clientAreaContainerClosedMenu {
    padding: 25px 20px 0;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 980px) {
  .clientAreaContainer {
      padding: 25px 20px 0;
      margin-left: 308px;
  }

  .clientAreaContainerClosedMenu {
      padding: 25px 20px 0;
  }

  .blocks {
    width: 78%;
    max-width: 800px;
  }
}
